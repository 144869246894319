<template>
  <div class="about">
  <div class="about2">
    <p class="about_p">How To Buy</p>
    <h1 class="about_h1">タイヤ購入について</h1>

    <div class="about_greet">
      <p>

      店頭や近くの倉庫に在庫があれば、その場でタイヤ交換ができます。<br>
      タイヤサイズによって在庫の用意がない場合は、前金をお預かりしてからタイヤ注文をします。<br>
      後日、タイヤが店頭に届きましたらお客様にご連絡します。<br>
      タイヤ交換後、店頭にてお支払いをお願いします。

      </p>
    </div>

    <div class="img_company1"><img src="../assets/exterior.jpg" alt="会社"></div>


    </div>

    <div>
    <Footer/>
    </div>

  </div>
</template>


<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'AboutView',
  data(){
    return{
      founded: ''
      }
    },
  components: {
    Footer
  },
  mounted: function() {
    this.updateTime();
    },
  methods:{
    updateTime: function() {
    let currentdate = new Date()
    if (parseInt(currentdate.getMonth()) >= 9){
      this.founded = currentdate.getFullYear()-1973
    }else{
      this.founded = currentdate.getFullYear()-1974
    }
    }
  }
}
</script>

<style scoped>
.about2{
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 3rem;
}
.about{
  padding-left: 0rem;
  padding-right: 0rem;
  padding-bottom: 0rem;
}
.about_h1{
  margin-top: -0.5rem;
  padding-bottom: 2rem;
  text-align:left;
  font-size: 1.4rem;
}
.about_p{
  padding-top: 6rem;
  text-align:left;
  font-size: 0.9rem;
}
.img_gentleman img{
  width: 30%;
}
.img_company1 img{
  width: 100%;
  height: 100%;
}
.img_company1{
  padding-top: 3rem;
}

.about_summary h1{
  font-size: 1.4rem;
  text-align:left;
  padding-top: 2rem;
}


.about_address{
    font-size: 0.9em;
    font-family: TTNormsPro-Bold,sans-serif;
    font-weight: 540;
    line-height: 1.5em;
}
.about_history{
    font-size: 0.9em;
    font-family: TTNormsPro-Bold,sans-serif;
    font-weight: 540;
    line-height: 1.5em;
}
.about_contents{
    font-size: 0.9em;
    font-family: TTNormsPro-Bold,sans-serif;
    font-weight: 540;
    line-height: 1.5em;
}
.about_time{
    font-size: 0.9em;
    font-family: TTNormsPro-Bold,sans-serif;
    font-weight: 540;
    line-height: 1.5em;
}
.about_tel{
    font-size: 0.9em;
    font-family: TTNormsPro-Bold,sans-serif;
    font-weight: 540;
    line-height: 2.5em;
    padding-bottom: 50px;
}

.about dt{
  float: left;
  font-size: 0.84rem;
  font-weight: 600;
  letter-spacing: 0.03rem;
  line-height: 2rem;
}

.about dt::after{
  content: ":"
}
.about dd{
  text-align:left;
  padding-left: 5.2rem;
  font-size: 0.84rem;
  font-weight: 600;
  letter-spacing: 0.03rem;
  padding-left: 8rem;
  line-height: 2rem;
}

.about_greet p{
  font-size: 0.9rem;
  text-align:left;
  line-height: 1.8;
  letter-spacing: 0.03rem;
  font-weight: 500;
}

</style>
